import styled from "styled-components"

export const Container = styled.div`
  flex: 1;
`

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;

  svg {
    margin-right: 6px;
  }
`
