import React, { useEffect } from "react"
import InputMask from "react-input-mask"

import Description from "../Description"

import { Container, Wrapper, Form, Item, Fields, CTA } from "./styles"

export default function Contact({ width, negative, background, title }) {
  useEffect(() => {
    console.log(width)
  }, [])

  return (
    <Container negative={negative} background={background}>
      {title && <Description centered negative={negative} title={title} />}
      <Wrapper width={width} hasTitle={title}>
        <Form
          name="contact"
          method="POST"
          data-netlify="true"
          action="/sucesso"
        >
          <input type="hidden" name="contact" value="contact" />
          <Fields>
            <Item>
              <p>Nome</p>
              <input type="text" name="name" />
            </Item>
            <Item>
              <p>E-mail</p>
              <input type="email" name="email" />
            </Item>
            <Item>
              <p>Telefone</p>
              <InputMask mask="+99 99 999 999 999" maskChar=" " />
            </Item>
            <Item full>
              <p>Especificações</p>
              <textarea name="message" />
            </Item>
            {/* <Item>
              <div data-netlify-recaptcha="true"></div>
            </Item> */}
          </Fields>
          <CTA type="submit">Enviar</CTA>
        </Form>
      </Wrapper>
    </Container>
  )
}
