import styled from "styled-components"

import BannerContato from "../../assets/banner-contato.jpg"

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  background: url(${BannerContato});
  background-size: cover;
  color: white;
  font-family: "Montserrat";
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 2px 0px rgba(0, 0, 0, 0.35);
`

export const HeroTitle = styled.h1`
  font-size: 42px;
`

export const HeroSubtitle = styled.p`
  max-width: 360px;
  text-align: center;
  line-height: 24px;
`

export const Divider = styled.div`
  height: 4px;
  width: 62px;
  background: #fff;
  margin: 16px 0;
`
