import styled, { css } from "styled-components"
import colors from "../../styles/colors"
import devices from "../../styles/devices"

export const Container = styled.div`
  background: "#fff";
  /* padding: 40px 0; */
  flex: 1;

  padding-top: 40px;

  ${({ background }) =>
    background &&
    css`
      background: url(${background});
      background-size: cover;
    `}

  ${({ negative }) =>
    negative &&
    css`
      color: white;
    `}
`

export const Wrapper = styled.div`
  max-width: 980px;
  margin: 0 auto;
  padding: ${({ hasTitle }) => (hasTitle ? "40px" : "0")} 0;
  height: 100%;

  display: flex;
  ${({ width }) =>
    width &&
    css`
      max-width: ${width};
    `}
    
  @media (max-width: ${devices.mobileL}) {
    max-width: 380px;
  }
`

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  :first-child {
    margin-top: 0;
  }

  ${({ full }) =>
    full &&
    css`
      height: 100%;
      flex: 1;
    `}

  p {
    font-weight: bold;
    font-size: 18px;
  }

  input {
    font-size: 16px;
    border: 0;
  }

  textarea {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  input,
  textarea {
    font-family: "Montserrat";
    border: 0;
    margin-top: 8px;
    padding: 12px;
    background: #ddd;
  }
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const CTA = styled.button`
  cursor: pointer;
  margin-top: 18px;
  font-family: "Montserrat";
  text-decoration: none;
  padding: 12px 28px;
  text-transform: uppercase;
  font-weight: bolder;
  color: #fff;
  background: ${colors.blue};
  border: 0;
  width: 200px;
`
