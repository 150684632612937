import React from "react"
import { MdMap } from "react-icons/md"

import { Container, Loading } from "./styles"

export default function Map() {
  return (
    <Container>
      <Loading>
        <MdMap size={48} />
        <h3>Carregando mapa...</h3>
      </Loading>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3653.6009603023463!2d-46.88650568501995!3d-23.69022418461903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cfac142dc85b35%3A0x8ac47ee780f1f915!2sEstr.%20da%20Ressaca%2C%20Itapecerica%20da%20Serra%20-%20SP!5e0!3m2!1sen!2sbr!4v1568152226726!5m2!1sen!2sbr"
        height="450"
        frameborder="0"
        style={{ border: 0, width: "100%" }}
      ></iframe>
    </Container>
  )
}
