import React from "react"

import DefaultLayout from "../../layouts/default"

import Contact from "../../components/Contact"
import Content from "../../components/Content"
import Info from "../../components/Info"

import { Hero, HeroTitle, Divider, HeroSubtitle } from "./styles.styled"

export default () => (
  <DefaultLayout>
    <Hero>
      <HeroTitle>Contato</HeroTitle>
      <Divider />
      <HeroSubtitle>Tire suas dúvidas com a gente</HeroSubtitle>
    </Hero>
    <Content padding horizontal>
      <Contact />
      <div style={{ margin: "0 12px" }}></div>
      <Info />
    </Content>
  </DefaultLayout>
)
