import React from "react"
import { MdPhone, MdMail, MdPlace } from "react-icons/md"

import Map from "../Map"

import { Container, InfoItem } from "./styles"

export default function Info() {
  return (
    <Container>
      <h3>Informações</h3>
      <InfoItem>
        <MdPhone />
        <p>(11) 963-229-897</p>
      </InfoItem>
      <InfoItem>
        <MdMail />
        <p>comercial@rentalsef.com.br</p>
      </InfoItem>
      <InfoItem>
        <MdPlace />
        <p>Estrada da Ressaca, 1499 - Itapecerica da Serra - SP</p>
      </InfoItem>
      <InfoItem>
        <Map />
      </InfoItem>
    </Container>
  )
}
